import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';

export class FwLink extends LitElement {

  static get properties() {
    return {
	node: { type: String },
        base: { type: String },
        edit: { type: Boolean },
        path: { type: String },
        del: { type: String },
        write: { type: String },
	description: { type: String },
        type: { type: String },
        title: { type: String },
	url: { type: String },
	name: { type: String },
	internal: { type: String },
	network: { type: String },
	mimetype: { type: String },
        size: { type: String },
        fetch: { type: String }                                                // Allow link to expand itself.
    };  
  }

  constructor() {
    super();
    this.base = "";
    this.fetch = false; 
  }

  // If element has a node and a URL do nothing, else try to load it.
  async fetchLink() {
	if (this.node && !this.url) {
            const url = this.public ? 
                'https://go.fuqua.duke.edu/alfresco/s/fuqua/article/public?id=' + this.node: 
                  this.base + '/fw/api/content/article/' + this.node ;          
            const response = await fetch(url, {
                credentials: 'include'
            });
            const json = await response.json();
            if (json) {
                //this.article = json;
            }
	}
  }
  
  isInternal() {    
      return (this.internal && this.internal === "true") || 
              ( this.url && (this.url.indexOf("/alfresco/") > -1 || this.url.indexOf("/share/") > -1));

  }
  
  isMimeType(mime) {
    return this.mimetype && this.mimetype === mime;
  }
  isPDF() {
    return (this.url && this.url.endsWith(".pdf")) || this.isMimeType('application/pdf');  
  }
  isWord() {
    return (this.url && this.url.endsWith(".docx")) || 
            (this.url && this.url.endsWith(".doc")) || this.isMimeType('application/docx');  
  }
  isExcel() {
    return (this.url && this.url.endsWith(".xlsx")) || 
            (this.url && this.url.endsWith(".xls")) || this.isMimeType('application/xlsx');      
  }
  isPowerPoint() {
    return (this.url && this.url.endsWith(".pptx")) || 
            (this.url && this.url.endsWith(".ppt")) || this.isMimeType('application/pptx');      
  }
  
  render() {
	return this.url ? 
		html`
                    <a href="${this.getUrl()}" target="${this.isInternal() ? "_self": "_blank"}" ?download=${this.type === 'cm:content'}>${this.title ? html`${this.title}`: html`${this.name}`}
                        ${this.isInternal() ? html``: html`<i title="External Link" class="fas fa-external-link-alt"></i>`}
                        ${this.network && this.network === "true" ? html`<i title="VPN Required" class="fas fa-lock"></i>`: html``}
                        ${this.isPDF() ? html`<i title="PDF File" class="fas fa-file-pdf"></i>`: html``}
                        ${this.isWord() ? html`<i title="Word File" class="fas fa-file-word"></i>`: html``}
                        ${this.isExcel() ? html`<i title="Excel File" class="fas fa-file-excel"></i>`: html``}
		        ${this.isPowerPoint() ? html`<i title="Powerpoint File" class="fas fa-file-powerpoint"></i>`: html``}
		    </a>
                    <slot></slot>
                `: html``;
  }

 createRenderRoot() {
          	return this;
 }

 getUrl() {
     return this.url.startsWith("/") ? this.base + this.url : this.url;
 }
}
if (!customElements.get('fw-link')) customElements.define('fw-link', FwLink);
